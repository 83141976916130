import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { headerScreenLabels, apiData } from "../../../common/common-types";
import MyRoute from "../../../hoc/MyRoute";
import Header from "../../../components/Layout/Header/HeaderSubmitBill";
import { changeActiveTab, showLoader } from "../../../../src/App/redux/action";
import { withStyles } from "@material-ui/styles";
import OffersComponent from "./components/OffersComponent";
import {
  getMerchantOffers,
  clearOffers,
  clearOfferDetails,
} from "./redux/actions";
import AlertMessage from "../../../common/AlertMessage/AlertMessage";
import { resetFilter } from "../../../components/Filter/redux/action";
import {
  clearSortBy,
  clearProductView,
  setFromOrderFlag,
  getCartItemCount,
} from "../Redeem/redux/action";
import {
  resetOffersSearchText,
  searchOffers,
} from "../../../components/Search/redux/action";
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "../../../common/localStorage";
import { Grid } from "@material-ui/core";
import Footer from "../../../components/Layout/Footer/Footer";
import OfferDetailsContainer from "../OfferDetails/OfferDetailsContainer";

const mapDispatchToProps = {
  changeActiveTab,
  resetOffersSearchText,
  getMerchantOffers,
  searchOffers,
  clearSortBy,
  clearProductView,
  setFromOrderFlag,
  resetFilter,
  showLoader,
  clearOffers,
  getCartItemCount,
  clearOfferDetails,
};

const mapStateToProps = (state) => {
  return {
    offersList: state.reducerOffers.offersList,
    pageNum: state.reducerOffers.pageNum,
    uploadingStatus: state.reducerBillUploadProgress.uploadingStatus,
    searchOffer: state.reducerSearch.searchOffer,
    cartItemCount: state.reducerRedeem.cartItemCount,
    loader: state.reducerApp.showLoader,
    merchantDetailsResponse: state.reducerDashboard.merchantDetailsResponse,
  };
};
const styles = (theme) => {
  return {
    root: {
      height: "100%",
      overflow: "hidden",
      backgroundColor: theme.palette.common.globalBackground,
    },
  };
};

class OffersContainer extends Component {
  state = {
    searchActive: false,
    hasMore: false,
    datarcv: false,
    offer_id: null,
  };
  async componentDidMount() {
    const {
      changeActiveTab,
      clearSortBy,
      clearProductView,
      resetFilter,
      setFromOrderFlag,
    } = this.props;
    changeActiveTab(1);
    clearSortBy();
    clearProductView();
    resetFilter();
    // setFromOrderFlag(true);
    if (this.props.searchOffer && this.props.searchOffer.length > 0) {
      this.setState({
        searchActive: true,
      });
    }
    const {
      getMerchantOffers,
      showLoader,
      searchOffer,
      pageNum,
      getCartItemCount,
      offersList,
    } = this.props;

    if (getStorageItem("user")) {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("customer_id", JSON.parse(getStorageItem("user")).id);
      bodyFormData.append("merchant_id", apiData.merchant_id);
      await getCartItemCount(bodyFormData);
      if (offersList.length === 0) {
        showLoader(true);
        const offerData = new URLSearchParams();
        offerData.append("platform", apiData.platform);
        offerData.append("merchant_id", apiData.merchant_id);
        offerData.append(" page_num", 1);
        offerData.append("search_text", searchOffer ? searchOffer : "");
        await getMerchantOffers(offerData);
        this.setState({
          datarcv: true,
        });
      }
      // if (
      //   getStorageItem("offerid") &&
      //   document.getElementById(getStorageItem("offerid"))
      // ) {
      //   document.getElementById(getStorageItem("offerid")).scrollIntoView({
      //     behavior: "auto",
      //     block: "center",
      //     inline: "center",
      //   });
      // }
      await this.setState({
        hasMore: pageNum ? true : false,
      });
      showLoader(false);
    }
  }

  componentDidUpdate() {}
  componentWillUnmount() {
    // removeStorageItem("offerid");
    // clearOfferDetails();
    // clearOffers();
  }
  viewOfferDetails = async (details) => {
    await this.setState({
      offer_id: details.id,
    });
    // setStorageItem("offerid", details.id);
    this.props.history.push({
      pathname: "/offers-detail/" + details.id,
    });
  };

  toggleSearchBox = (e) => {
    e.preventDefault();
    const { searchActive } = this.state;
    this.setState({
      searchActive: !searchActive,
    });
  };

  searchCall = async (e) => {
    e.preventDefault();
    this.props.showLoader(true);
    await this.props.clearOffers();
    await this.getOffers();
    await this.setState({
      hasMore: false,
    });
    this.props.showLoader(false);
  };

  onSearchText = async (e) => {
    const re = /^[0-9a-zA-Z][0-9a-zA-Z\s\b]*$/;
    if (e.target.value === "") {
      let value = "";
      await this.setState({
        hasMore: false,
      });
      await this.props.clearOffers();
      await this.props.searchOffers(value);
      await this.getOffers();
    } else {
      if (re.test(e.target.value)) {
        let value = e.target.value;
        await this.props.searchOffers(value);
      }
    }
  };

  resetSearch = async (e) => {
    this.props.showLoader(true);
    await this.setState({
      hasMore: false,
    });
    await this.props.clearOffers();
    await this.props.resetOffersSearchText();
    await this.getOffers();
    this.props.showLoader(false);
  };

  getOffers = async (isScroll) => {
    const { getMerchantOffers, showLoader, searchOffer, pageNum } = this.props;
    if (pageNum) {
      !isScroll && showLoader(true);
      const offerData = new URLSearchParams();
      offerData.append("platform", apiData.platform);
      offerData.append("merchant_id", apiData.merchant_id);
      offerData.append(" page_num", pageNum);
      offerData.append("search_text", searchOffer);

      await getMerchantOffers(offerData);

      await this.setState({
        datarcv: true,
        hasMore: pageNum ? true : false,
      });
      showLoader(false);
    } else {
      await this.setState({
        hasMore: false,
      });
    }
  };

  render() {
    const {
      classes,
      getMerchantOffers,
      offersList,
      showLoader,
      pageNum,
      resetOffersSearchText,
      cartItemCount,
      loader,
      merchantDetailsResponse,
    } = this.props;
    const merchant_name =  merchantDetailsResponse && merchantDetailsResponse?.merchant_name; 
    const { hasMore } = this.state;
    const { searchActive } = this.state;
    let offerProps = {
      getMerchantOffers,
      offersList,
      showLoader,
      onSearchTextChange: this.onSearchText,
      searchOffer: this.props.searchOffer,
      resetSearch: this.resetSearch,
      searchCall: this.searchCall,
      searchActive,
      getOffer: this.getOffers,
      pageNum,
      hasMore,
      noRecords: false || (offersList && offersList.length === 0),
      resetOffersSearchText,
      loader,
      datarcv: this.state.datarcv,
      merchant_name,
      viewOfferDetails: this.viewOfferDetails,
    };
    return (
      <Fragment>
        <Header
          screenLabel={headerScreenLabels.offers}
          backBtn={window.location.pathname === "/offers" ? false : true}
          cartItemCount={cartItemCount}
          heading={
            window.location.pathname === "/offers" ? "Offers" : "Offers Details"
          }
          toggleSearchBox={this.toggleSearchBox}
        />
        <AlertMessage />
        <MyRoute
          fullHeight={window.location.pathname === "/offers" ? false : true}
          billUploadStatus={this.props.uploadingStatus}
        >
          {window.location.pathname === "/offers" ? (
            <div className={classes.root}>
              <OffersComponent {...offerProps} />
            </div>
          ) : (
            <OfferDetailsContainer OfferId={this.state.offer_id} />
          )}
        </MyRoute>
        <Grid item>{window.location.pathname === "/offers" && <Footer />}</Grid>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(OffersContainer));
